$(document).ready(function () {
    var map = document.getElementById('footer-map');
    if (!map) {
        return;
    }

    ymaps.ready(function () {
        var yMap = new ymaps.Map('footer-map', {
            center: [map.getAttribute("data-point-x"), map.getAttribute("data-point-y")],
            zoom: 15,
            controls: [],
            behaviors: []
        });

        yMap.geoObjects.add(new ymaps.Placemark(yMap.getCenter()));
    });
});

$(document).ready(function () {

    var $slider = $('.slider');

    if (!$slider.length) {
        return;
    }

    function setWidth() {
        var winWidth = $window.width();

        if ( winWidth > 991 ) {
            viewCount = isGallery ? 2 : 4;
        } else if ( winWidth > 767 ) {
            viewCount = isGallery ? 1 : 3;
        } else {
            viewCount = 1;
        }

        $content.css('left', 0);
        $left.prop( 'disabled', true );
        $right.prop( 'disabled', false );

        if ( count <= viewCount ) {
            $left.prop( 'disabled', true );
            $right.prop( 'disabled', true );
            return;
        }

        wrapWidth = $wrap.width();
        if (wrapWidth < cardWidth) {
            cardWidth = wrapWidth;
        }

        between = viewCount > 1 ? (wrapWidth - viewCount * cardWidth) / (viewCount - 1) : 0;
        width = Math.round(between * (count - 1) + cardWidth * count);
        $content.width( width );
    }

    var isGallery = $slider.hasClass('gallery'),
        cardWidth = isGallery ? 440 : 190,
        $window = $(window),
        count = $slider.find('.card,.slide').length,
        $wrap = $slider.find('.slider-wrap-content'),
        $content = $slider.find('.slider-content'),
        $left = $slider.find('.slider-btn-left'),
        $right = $slider.find('.slider-btn-right'),
        viewCount,
        wrapWidth,
        between,
        width;

    setWidth();

    $window.on( 'resize', setWidth );

    $left.on('click', function () {
        if ( $content.is(':animated') ) {
            return;
        }
        var left = Math.round(parseInt($content.css('left')) + cardWidth + between);
        if (left > 0) {
            left = 0;
        }

        $content.animate({ left: left }, 'normal');
        $left.prop( 'disabled', !left );
        $right.prop( 'disabled', false );
    });

    $right.on('click', function () {
        if ( $content.is(':animated') ) {
            return;
        }
        var left = Math.round(parseInt($content.css('left')) - cardWidth - between);

        $content.animate({ left: left }, 'normal');
        $left.prop( 'disabled', false );
        $right.prop( 'disabled', wrapWidth >= width + left );
    });

});

$(document).ready(function () {

    var $categories = $('select.categories');

    if (!$categories.length) {
        return;
    }

    $categories.on('change', function () {
        window.location = $categories.val();
    });

});

$(document).ready(function () {

    var $qty = $('.qty');

    if (!$qty.length) {
        return;
    }

    $qty.on('change', function () {
        var $this = $(this);

        $.get('get.json', {
            id: $this.data('id'),
            qty: $this.val()
        }, function(data) {
            $this.parent().next().find('span').text(data.price);
        });
    });

});

$(document).ready(function () {

    var $add = $('.add');

    if (!$add.length) {
        return;
    }

    var $body = $('body'),
        $cart = $('.header-top .cart > span'),
        finish = $cart.offset();

    var $this = $(this),
            $qty = $this.prev().find('select'),
            start = $this.offset();


        $this.clone().prependTo($body).css({
            position: 'absolute',
            top: start.top,
            left: start.left
        }).animate({
            top: finish.top,
            left: finish.left
        }, 1500, 'linear', function () {
            $(this).remove();
        });

        $.get('add.json', {
            id: $qty.data('id'),
            qty: $qty.val()
        }, function(data) {
            $cart.text(data.total);
        });
    });

});

$(document).ready(function() {
    var $slide = $('.slide');

    if (!$slide.length) {
        return;
    }

    $slide.fancybox({
        helpers: {
            overlay: {
                locked: false
            }
        }
    });
});

$(document).ready(function() {
    var $payChange = $('#pay-change');

    if (!$payChange.length) {
        return;
    }

    var $payForm = $('#pay-form');

    $payChange.on('change', function () {
        $payForm.slideToggle();
    });
});

$(document).ready(function() {
    var $card = $('.card.product');

    if (!$card.length) {
        return;
    }

    var $window = $(window),
        size = function () {
            if ($window.width() > 974) {
                $card.removeAttr('style');
                return;
            }
            var height = 0;
            $card.eq(0).find('> .card-content').find('> *').each(function() {
                height += $(this).outerHeight(true);
            });

            $card.height(height + 60);
        };

    size();
    $window.on('resize', size);
});